$whiteColor: #fff;
$grayColor: #858585;
$lineHeight: 17px;

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: $whiteColor;
  color: $grayColor;
  font-weight: 500;
  font-size: 14px;
  line-height: $lineHeight;

  .logo {
    position: absolute;
    z-index: 0;
    height: 147px;
    width: 147px;
    animation: loader 1.5s infinite linear;
  }
  .percents {
    position: relative;
    z-index: 1;
    height: $lineHeight;
    margin-top: calc(65px + $lineHeight);
    margin-bottom: 65px;
  }
  .loading {
    position: relative;
    z-index: 1;
    height: 17px;
  }

  &.stopped {
    .logo {
      animation-play-state: paused;
      opacity: 0;
      transition: opacity 0.2s ease-in 0.1s;
    }
    .percents,
    .loading {
      color: $whiteColor;
      transition: color 1.5s ease-in;
    }
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.8s ease-in 0.5s;
  }
}
