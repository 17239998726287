.questionFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  padding-top: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.nativeReactTextInput {
  outline: none !important;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 24px;
  border-radius: 16px;
  color: #212121;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 50px;
  background: #fff;
  padding: 12px 20px;
  width: 100%;
  
  &:hover {
    outline: none !important;
    border: 1px solid #18397a;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #18397a;
  }
}

.formContainer {
  form {
    padding: 20px;

    h1 {
      margin: 0 0 8px 0;
      font-size: 18px;
      line-height: 48px;
      font-weight: 600;
    }
    .questionContainer {
      width: 100%;
      p {
        margin: 8px 0 8px 0;
        font-size: 16px;
        line-height: 32px;
        font-weight: 600;
      }

      .subtopicBlock {
        padding-left: 24px;
      }

      .topicBlock,
      .subtopicBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        row-gap: 16px;
        column-gap: 16px;
        > div {
          > div {
            padding: 0;
          }
        }

        .questionInput {
          flex: 1 1 300px;
        }
        .buttonBlock {
          height: fit-content;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          gap: 24px;
          flex: 0 0 170px;
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 32px 0 0 0;
  }

  @media screen and (min-width: 1024px) {
    // margin-top: 16px;

    form {
      max-height: 100%; //calc(100% - 82px); // navigation.height + navigation.margin
      padding: 20px 32px 24px 32px;
      margin: auto 0;
      overflow-y: auto;

      h1 {
        font-size: 32px;
      }
      .questionContainer {
        .question {
          width: 100%;
        }
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}

  