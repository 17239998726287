$whiteColor: #fff;
$lightGrayColor: #efefef;
$progressCircleSize: 200vh;
$progressBarWidth: 10px;

.progressCircle {
  display: none;
  pointer-events: none;

  @media screen and (min-width: 1024px) {
    display: block;
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    width: 65vw;
    height: 100vh;
    overflow: hidden;

    .shadowedCircle {
      position: relative;
      z-index: 1;
      top: -50vh;
      right: -30px;
      width: $progressCircleSize;
      height: $progressCircleSize;
      border-radius: 50%;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.07);
    }

    svg {
      position: absolute;
      z-index: 2;
      top: -50vh;
      left: 30px;
      width: $progressCircleSize;
      height: $progressCircleSize;

      circle {
        r: calc(100vh - 5px);
        cx: 100vh;
        cy: 100vh;
        stroke-width: 9px;
        stroke-dasharray: calc(3.14 * (100vh - 5px)); // половина окружности
        fill: transparent;
        transform-origin: center;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    width: 60vw;
  }
}
