.__modalRoot{
    &.modalWide {
      max-width: 100%;
      > div > div {
        max-width: 100% !important;
      }
    }
  }
  
  .modal {
    > div {
      > h3 {
        width: 100%;
      }
    }
    .headerModal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
  
      button {
        color: #D32F2F;
        &:hover span svg path {
          fill: #D32F2F;
        }
      }
    }
    .modalContent {
      width: 100%;
      .questionInput {
        width: 100%;
        > div {
          padding: 0;
        }
      }
    }
    .modalButtonsBlock {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 24px;
      gap: 24px;
      button {
        padding: 0 24px !important;
        max-width: fit-content;
      }
    }
  }