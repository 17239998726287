$navyColor: #18397a;
$whiteColor: #fff;

.selfAssessment {
  display: flex;
  align-items: center;
  width: 100%;
  color: $whiteColor;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  span {
    width: 100%;
    text-align: center;
    background: $navyColor;
    cursor: pointer;
  }

  button {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 400px;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;

    span {
      height: 351px;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      border-radius: 20px 0 0 0;
    }

    button {
      width: 100%;
      height: 44px;
      display: block;
      border: none;
      border-radius: 0 20px 0 0;
      background: $navyColor;

      svg path {
        fill: $whiteColor;
      }
    }
  }
}
