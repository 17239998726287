$lightBlackColor: #212121;

.form {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  padding: 0 12px 24px 12px;
  background: rgba(255, 255, 255, 0.32);
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(20px);
  border-radius: 12px;

  h1 {
    display: flex;
    align-items: center;
    width: 100%;
    margin: -20px 0 20px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $lightBlackColor;
    cursor: default;

    &.clickable {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 72vw;
    max-width: 1328px;
    padding: 0 80px 40px 80px;
    border-radius: 24px 24px 0 24px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

    h1 {
      font-size: 32px;
      line-height: 48px;
    }
  }

  @media screen and (min-width: 1920px) {
    padding: 0 112px 40px 112px;

    h1 {
      margin: -30px 0 40px 0;
      font-size: 40px;
      line-height: 60px;
    }
  }
}
