$navyColor: #18397a;

.pagination {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  color: $navyColor;
}
