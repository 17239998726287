.__modalRoot{
  &.modalWide {
    max-width: 100%;
    > div > div {
      max-width: 100% !important;
    }
  }
}
.modal {
  max-width: 100% !important;
  width: fit-content !important;

  .columnWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    .rowWrapper {
      display: flex;
      max-width: fit-content;
      flex-wrap: wrap;
      
    }
  
    .buttonWrapper {
      display: flex;
      margin-left: 25px;
      margin-bottom: 25px;
      flex-direction: column;
      align-items: flex-start;
    }
    
    li {
      list-style-type: none;
      margin-left: 25px;
  
      svg {
        margin-right: 10px;
        vertical-align: middle;
      }
  
      &.accepted {
        color: green;
      }
      
      &.rejected {
        color: red;
      }
      
      &.loading {
        color: gray;
      }
  
      path:hover {
        color: gray;
      } 
    }
  
    .disabled {
      color: gray;
    }
  }
}

.docButton {
  padding: 0 24px !important;
  svg {
    fill: #1F4A9D;
  }
}