$whiteColor: #fff;
$blackColor: #263238;
$lineHeight: 17px;

@keyframes clouds {
  0% {
    transform: translate(0%);
  }
  50% {
    transform: translate(25%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(0%);
    animation-timing-function: ease-in;
  }
}

.notFound {
  position: fixed;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: $whiteColor;
  color: $blackColor;

  .notFoundSvg {
    position: absolute;
    z-index: 0;
    width: 80vw;

    :global(.cloud) {
      animation: clouds 10s infinite;
    }
  }
  .errorText {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 39vh;

    .errorNumber {
      font-weight: 700;
      font-size: 64px;
      line-height: 78px;
    }
    .error {
      margin-top: -16px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    .explanation {
      margin-top: -4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    .backBtn {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    .notFoundSvg {
      width: 43vw;
      max-width: 827px;
    }

    .errorText {
      .errorNumber {
        font-size: 151px;
        line-height: 184px;
      }
      .error {
        margin-top: -42px;
        font-size: 40px;
        line-height: 49px;
      }
      .explanation {
        margin-top: -7px;
        font-size: 14px;
        line-height: 17px;
      }

      .backBtn {
        margin-top: 39px;
      }
    }
  }
}
