.finishHeader {
  margin: 20px 0 0 6vw;
  font-size: 24px;
  line-height: 36px;
}

.container {
  flex-direction: column;
  padding: 0 24px;

  .chartArea {
    display: flex;
    height: 100vw;
    width: calc(
      100% + 24px
    ); // magic number from marginSize in Chart component
    margin: 0 -24px;
    padding: 20px;
  }

  .textArea {
    max-width: 600px;

    h1 {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
    }

    button {
      width: fit-content;
      margin: 32px auto;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0 5vw;

    .chartArea {
      height: 80vh;
      width: 55%;
      margin: unset;
      padding: 10px;
    }

    .textArea {
      width: 45%;
      margin: auto auto auto 0;
      height: fit-content;

      .owerflowContainer {
        height: 55vh;
        padding-right: 3vw;
        overflow: auto;
      }

      h1 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 36px;
      }

      h4 {
        margin-bottom: 0;
      }

      .text {
        height: fit-content;
        padding-right: 20px;
        p {
          margin-block: 0;
        }
      }

      button {
        margin: 0;
        margin-top: 28px;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    padding: 0 12vw;
  }
}
