@import-normalize;

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./assets/fonts/montserrat/montserrat-regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url("./assets/fonts/montserrat/montserrat-semibold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("./assets/fonts/montserrat/montserrat-bold.ttf") format("truetype");
}

* > * {
  --navyColor: #143471;
  --lightGrayColor: #f0f0f0;
  --grayColor: #d8d8d8;
  box-sizing: border-box;
}

/* Firefox scroll */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--grayColor) var(--lightGrayColor);
}

/* Chrome/Edge/Safari scroll */
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: var(--lightGrayColor);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--grayColor);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  line-height: 1.45em;
  background: #ffffff;
  color: #000000;
  overflow: hidden;
  scroll-behavior: smooth;
}

main {
  position: relative;
  width: 100%;
  height: calc(100% - 76px);
  overflow-y: auto;
  padding-bottom: 5px;
}

@media screen and (min-width: 1024px) {
  html,
  body,
  #root {
    font-size: 16px;
    line-height: 24px;
  }

  main {
    height: calc(100% - 96px);
    overflow-y: hidden;
  }
}
@media screen and (min-width: 1280px) {
  main {
    max-width: 1920px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

button {
  cursor: pointer;
}

input,
textarea,
button {
  font-family: inherit;
}
a:link {
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: var(--navyColor);
}
.clickable {
  cursor: pointer;
}
