$greenColor: #4caf50;
$redColor: #d32f2f;
$blackColor: #212121;

.formContainer {
  form {
    min-height: 150px;
    padding: 20px;

    h1 {
      margin-bottom: 0;
      margin-top: -8px;

      .tableArrow {
        width: 16px;
        height: 16px;
        padding: 4px 2px;
        margin-left: 4px;
        margin-bottom: -2px;

        &.top {
          transform: rotate(180deg);
          margin-bottom: -1px;
        }
      }
    }

    .curSourceName {
      position: relative;
      align-self: flex-start;
      width: 100%;
    }

    .headerContainer {
      width: 100%;
      white-space: nowrap;
      font-size: 22px;

      &.companyHeaderContainer {
        white-space: unset;
      }

      span {
        display: inline-block;
      }
      .icons {
        display: inline-block;
      }
    }

    .infoIcon {
      height: 16px;
      width: 16px;
      margin: 0 5px -2px 10px;

      &.topIcon {
        align-self: flex-start;
        margin-top: 8px;
      }
    }

    .infoLabel {
      font-size: 12px;
      line-height: 20px;
    }

    .addSourceFormBtn,
    .sourceName {
      margin-top: 20px;
    }

    .sourceName {
      align-self: flex-start;
      width: 100%;
    }

    .componentLabel {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blackColor;
      margin-bottom: 8px;

      span {
        float: right;
      }
    }

    .componentInputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 12px 0 20px 0;

      .componentInput {
        margin-bottom: 8px;
      }
    }

    .isNotAvailable {
      display: flex;
      flex-direction: column;
      width: 100%;

      .checkbox {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .info {
        width: 100%;
      }

      button {
        margin: 0 auto;
      }
    }

    .docButtonCont {
      width: 100%;
      text-align: center;
    }

    .addButton {
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .tableContainer {
      width: 100%;
      max-width: 1100px;
      overflow-x: auto;

      .table {
        width: 100%;
        margin: 16px 0 40px 0;
        border-spacing: 0 12px;

        th,
        td {
          font-size: 16px;
          line-height: 24px;
          padding-right: 26px;
          text-align: start;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          & > div {
            max-width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        th {
          font-weight: 600;
        }

        td {
          &.completed {
            color: $greenColor;
          }
          &.notCompleted {
            color: $redColor;
          }
        }

        .actions {
          svg {
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 32px 0 0 0;

    .addSourceNavBtn {
      margin: 0 calc(50% - 75px) 20px calc(50% - 75px);
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: 16px;
    height: calc(55% - 16px);

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      max-height: 100%; //calc(100% - 82px);
      padding: 20px 32px 24px 32px;
      overflow-y: auto;
      margin-top: auto;

      h1 {
        margin-top: -16px;

        .tableArrow {
          margin-left: 8px;
        }
      }

      .infoIcon {
        margin: 0 0 0 16px;
      }

      .infoLabel {
        font-size: 14px;
      }

      .curSourceName {
        margin: -45px 0 0 260px;
        width: unset;
      }

      .sourceName {
        width: 59%;
        max-width: 740px;
        margin-top: 12px;
      }

      .componentLabel {
        line-height: 32px;
      }

      .componentInputs {
        display: grid;
        grid-gap: 12px 16px;
        grid-template-rows: auto auto auto;
        grid-template-columns: 0.85fr 1fr 1fr 1fr 1fr 0.85fr;
        grid-template-areas:
          "header header header header header header"
          "input input input input input input"
          "input input input input input input";
        margin-top: 24px;

        .componentLabel {
          grid-area: header;
        }

        .componentInput {
          margin-bottom: 0;

          &.input1 {
            grid-column: 1 / 3;
          }
          &.input2 {
            grid-column: 3 / 5;
          }
          &.input3 {
            grid-column: 5 / 7;
          }
          &.input4 {
            grid-column: 1 / 4;
          }
          &.input5 {
            grid-column: 4 / 7;
          }
        }

        &.col2,
        &.col4 {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            "header header"
            "input input";
        }

        &.col3 {
          grid-template-rows: auto auto auto;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas:
            "header header header"
            "input input input"
            "input input input";
        }
      }

      .isNotAvailable {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .info {
          width: calc(100% - 250px);
        }
        .checkbox {
          line-height: 32px;
        }
        button {
          margin: 0;
          margin-left: auto;
        }

        &.fullWidth {
          width: 100%;

          & + button {
            margin-top: 10px;
          }
        }

        &.partWidth {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .docButtonCont {
        width: 100%;
        text-align: end;
      }

      .addButton {
        margin: 0 8px 0 auto;
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;

      .backBtn {
        order: -1;
      }

      .addSourceNavBtn {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    form {
      .infoLabel {
        font-size: 16px;
        line-height: 24px;
      }

      .componentInputs {
        grid-template-rows: auto auto;
        grid-template-columns: 0.85fr 1fr 1fr 1fr 0.85fr;
        grid-template-areas:
          "header header header header header"
          "input input input input input";

        .componentInput {
          &.input1,
          &.input2,
          &.input3,
          &.input4,
          &.input5 {
            grid-column: unset;
          }
        }
      }

      .isNotAvailable {
        justify-content: space-between;

        &.partWidth {
          width: calc(100% - 250px);
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      .curSourceName {
        margin-top: -50px;
        width: unset;
      }

      .componentInputs {
        &.col4 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-areas:
            "header header header header"
            "input input input input";
        }
      }
    }
  }
}
