$navyColor: #18397a;
$whiteColor: #fff;
$lightGrayColor: #efefef;

.calculatorContainer {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-bottom: 32px;

  .breadcrumps {
    width: 100%;
    padding: 8px 24px;
    white-space: nowrap;
    overflow-x: auto;
    font-size: 12px;
    color: #757575;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

    a {
      border-bottom: 1px solid rgba(#757575, 0.5);

      &:hover {
        border-bottom-color: rgba($navyColor, 0.8);
      }
    }
  }

  .content {
    flex-direction: column;
    height: 100%;
    max-width: 500px;
    padding: 0 25px;

    .chartArea {
      height: calc(100vw - 50px);
      width: calc(100vw - 50px);
      max-width: 400px;
      max-height: 400px;
      margin: 40px auto 20px auto;
    }

    .infoArea {
      max-width: 400px;
      margin: 0 auto 28px auto;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 100%;
    padding-top: 40px;
    overflow-y: auto;

    .breadcrumps {
      position: absolute;
      z-index: 1;
      padding-left: 5vw;
      margin-top: -40px;
    }

    .content {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: unset;
      min-height: 500px;

      .chartArea {
        width: 50%;
        max-width: 250px;
        height: 53%;
        margin: auto auto auto calc(25% - 100px);
      }

      .infoArea {
        width: 50%;
        max-width: 37vw;
        height: 53%;
        min-height: 250px;
        margin: auto auto auto 0;

        & > div {
          margin-left: 10%;
        }
      }
    }
  }

  @media screen and (min-width: 1680px) {
    .content {
      padding-top: 50px;

      .chartArea {
        max-width: 280px;
        max-height: 280px;
        margin-left: calc(25% - 140px);
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .breadcrumps {
      padding-left: 12vw;
    }
  }
}
