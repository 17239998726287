.formContainer {
  form {
    padding: 20px;

    h1 {
      margin: 0 0 8px 0;
      font-size: 16px;
      line-height: 20px;
    }

    .question {
      display: flex;
      flex-direction: column;
      width: 100%;

      .questionText {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .footerAnswer {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        > div {
          text-align: center;
        }
        svg {
          fill: #1F4A9D;
        }
        .boolAnswers {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 32px 0 0 0;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 16px;
    height: calc(55% - 16px);

    form {
      max-height: 100%; //calc(100% - 82px); // navigation.height + navigation.margin
      padding: 20px 32px 24px 32px;
      margin: auto 0;
      overflow-y: auto;

      h1 {
        font-size: 18px;
      }

      .question {
        .questionText {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
        .footerAnswer {
          display: flex;
          justify-content: space-between;   
          flex-direction: row;

          .boolAnswers {
            align-self: end;
            margin-top: auto;
            width: 24%;
            max-width: 300px;
          }
        }
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
