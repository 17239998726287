$greenColor: #4caf50;
$greyColor: #757575;

.dragndropWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 380px;
  left: 20px;
  top: 0px;
  border: 1px dashed $greyColor;
  border-radius: 12px;
  margin-bottom: 25px;
  text-align: center;

  svg {
    margin-right: 10px;
    fill: $greyColor;
    color: $greyColor;
  }

  h4 {
    color: $greyColor;
    margin-bottom: 0;
  }

  p {
    color: $greyColor;
    margin-top: 0;
  }
}