.transparent {
  fill: rgba(180, 180, 180, 0.1);
}

.circularBarplot {
  width: 100%;
  height: 100%;

  .shadowedBaseSheet {
    circle {
      cx: 0;
      cy: 0;
    }

    #gradient {
      cx: 50%;
      cy: 50%;
    }
    .scoreTextContainer {
      text-align: center;
    }
    .scoreValue {
      font-family: Montserrat;
      fill: #C4C4C4;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: center;

    }
    .scoreUnits {
      font-family: Montserrat;
      fill: #C4C4C4;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: center;
    }

    .scoreCircle {
      filter: drop-shadow(0px 4px 6px #dcdcdc);
      fill: #fefefe;
    }

    .scoreText {
      fill: rgb(85, 85, 85);
      text-anchor: middle;
      dominant-baseline: central;
      font-size: 18px;

      &.bold {
        font-size: 14px;
        font-weight: bold;
      }

      &.result {
        font-size: 32px;
        fill: white;
        text-shadow: -2px -2px 0 #18397A, 2px -2px 0 #18397A, -2px 2px 0 #18397A, 2px 2px 0 #18397A;
      }
    }
  }

  .barChartSheet {
    .bar {
      filter: drop-shadow(0px 1px 1px #d7d7d7);
    }
  }

  .textLabelsSheet {
    text {
      fill: #000;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .chartGrid {
    circle {
      fill: none;
    }
  }
}
